<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>
        <v-col cols="12" md="8">
          <v-select
            label="Select Card"
            v-model="SelCompany"
            :items="Company"
            item-text="card_name"
            item-value="card_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        item-key="application_id"
        :search="search"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-card style="position: sticky; top: 0px; z-index: 8" flat>
            <v-row class="mt-2 mb-2">
              <v-col cols="12" md="8">
                <div v-if="TDselected.length > 0 && Selstatus != 7">
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-select
                        label="Select Status"
                        v-model="SelstatusForUpade"
                        :items="StatusForUpdate"
                        item-text="status_name"
                        item-value="status_id"
                        dense
                        outlined
                        hide-details
                        @change="getreson"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="5" v-if="strcheckatatus != 3">
                      <v-select
                        label="Select Reason"
                        v-model="SelReasonForUpdate"
                        :items="ResonForUpdate"
                        item-text="reason_name"
                        item-value="reason_id"
                        outlined
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="5" v-if="strcheckatatus == 3">
                      <v-select
                        label="Select Card Type"
                        v-model="SelCardTypeForUpdate"
                        :items="CardTypeForUpdate"
                        item-text="card_name"
                        item-value="card_id"
                        hide-details
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn
                        medium
                        color="primary"
                        @click="btnsave"
                        class="fullwidth"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  dense
                  solo-inverted
                  flat
                  label="Search"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <!-- @click="BtnApprove(index)" -->
        <template v-slot:item.application_id="{ item }">
          <tr @click="BtnApprove(item.sr_no)">
            <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->
            <td style="text-align: left; width: 80px">
              {{ item.app_id }}
            </td>

            <td style="text-align: left; width: 65px">
              <img
                :src="$apiPath + item.photo"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="isViewAppOpened" persistent max-width="400px">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Application ID : {{ cApp.app_id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="btnclose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  :src="$apiPath + cApp.photo"
                  height="150px"
                  width="150px"
                  alt=""
                  class="imgpreview"
                />
                <div class="text-center" style="height: 30px">
                  <b>{{ cApp.firstName }}</b>
                </div>
                <div class="text-center" style="height: 30px">
                  {{ cApp.role_name }}
                </div>
                <div class="text-center" style="height: 30px">
                  {{ cApp.mediaType_name }}
                </div>
                <div class="text-center" style="height: 30px">
                  {{ cApp.print_text }}
                </div>
                <div class="text-center" style="height: 30px">
                  <v-text-field
                    label="Scann"
                    v-model="barcode"
                    outlined
                    maxlength="25"
                    @change="updatebarcode(cApp.application_id)"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>

    <v-overlay :value="overlay" style="z-index: 1009">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/Admin/ViewApplication.vue";
export default {
  components: {},
  data() {
    return {
      barcode: "",
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",
      Status: [
        {
          status_id: 8,
          status_name: "Pending",
          status: "O",
          status_named: "Pending",
        },
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },
        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 7,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },
      ],
      SelstatusForUpade: "",
      StatusForUpdate: [
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },

        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 8,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },
      ],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
        uflag: "0",
        FlagCompanyName: "",
        FlagEmail: "",
      },

      postBodybarcode: {
        application_id: "",
        event_id: "",
        venue_id: "",
        barcode: "",
      },

      headers: [
        {
          text: "AppID / Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5",
          sortable: false,
        },

        {
          text: "Name",
          value: "firstName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "role_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Card",
          value: "mediaType_name",
          align: "left",
          class: "blue lighten-5",
        },
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Media Barcode Scann");
    this.$store.commit("setEventName", sessionStorage.display_event_name);
    this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    //    this.bindCartype(this.$store.state.eventInfo.EventId);
    this
      .bindCompany
      // this.$store.state.eventInfo.EventId
      ();
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    // watch: {
    //   // chkselected: function () {
    //   //   // this.sheet=true;
    //   //   if (this.chkselected.length > 0) {
    //   //     alert("greater");
    //   //   }
    //   // },
    // },

    getreson: function () {
      // alert(this.SelstatusForUpade);
      // this.bindReson(this.SelstatusForUpade);
      this.strcheckatatus = this.SelstatusForUpade;
    },
    chkall: function () {},

    updatebarcode: async function (application_ids) {
      this.postBodybarcode.event_id = this.$store.state.eventInfo.EventId;
      this.postBodybarcode.venue_id = this.$store.state.eventInfo.VenueId;
      if (this.barcode.length <= 0) {
        this.showSnackbar("error", "Scann barcode !");
        return;
      }
this.overlay = true;
      this.postBodybarcode.application_id = application_ids;
      this.postBodybarcode.barcode = this.barcode;
      await this.$axios
        .put("Media/setBarcode", this.postBodybarcode)
        .then((res) => {
          console.log(res.data.message);
          if (res.data.status == "Success") {
            if (res.data.message == null || res.data.message == "") {
              if (this.desserts.length - 1 > this.cAppIndex) {
                this.cAppIndex = this.cAppIndex + 1;
                this.cApp = this.desserts[this.cAppIndex];
              } else {
                this.isViewAppOpened = false;
                this.Reload(
                  this.$store.state.eventInfo.EventId,
                  this.$store.state.eventInfo.VenueId,
                  this.SelCompany
                );
              }
              this.showSnackbar("Success", "Barcode Updated !");
            } else if (res.data.message == "FOUND") {
              this.showSnackbar("error", "Barcode already exists");
            } else if (res.data.message == "INACTIVE") {
              this.showSnackbar("error", "Barcode inactive");
            }
          }
        })
        .catch()
        .finally(()=>{
          this.overlay = false;
        });

      this.barcode = "";

      // else {
      //   this.postBodybarcode.application_id = application_ids;
      //   this.postBodybarcode.barcode = this.barcode;
      //   console.log(this.postBodybarcode);
      //   axios
      //     .put(this.$apiPath + "Media/setBarcode", this.postBodybarcode, {
      //       headers: { Appkey: this.$aKey },
      //     })
      //     .then((res) => {

      //       console.log(res.data.message);
      //       if (res.data.status == "Success") {
      //         if (res.data.message == 'FOUND') {
      //           this.showSnackbar("error", "Barcode already exists1gggg");
      //         } else if (res.data.message == 'INACTIVE') {
      //           this.showSnackbar("error", "Barcode inactive");
      //         }
      //         else {
      //           this.showSnackbar("Success", "Barcode Updateds !");
      //         }
      //       }
      //     })
      //     .catch()
      //     .finally();
      //   this.Reload(
      //     this.$store.state.eventInfo.EventId,
      //     this.$store.state.eventInfo.VenueId,
      //     this.SelCompany
      //   );
      //   this.isViewAppOpened = false;
      // }
    },

    BtnApprove: function (ID) {
      this.cAppIndex = ID - 1;
      this.cApp = this.desserts[this.cAppIndex];
      //  this.cApp.dob = this.cApp.dob.slice(0, 10);
      //  this.appstatus = status_ID;
      sessionStorage.COMPANYID = this.SelCompany;
      this.isViewAppOpened = true;
      this.barcode = "";
      // this.barcode.focus();
    },
    btnclose: function () {
      this.isViewAppOpened = false;
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.SelCompany
      );
    },

    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.SelCompany
      );
    },

    btnsearch: function () {
      this.TDselected = [];
      this.desserts = [];
      if (this.SelCompany.length <= 0) {
        this.showSnackbar("error", "Select Company !");
      } else {
        this.desserts = [];
        this.chkselected = [];
        //  this.appstatus = this.Selstatus;
        this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany
        );
        // this.bindReson(this.Selstatus);
      }
    },

    appNext: function () {
      if (this.cAppIndex < this.desserts.length - 1) {
        this.cAppIndex = this.cAppIndex + 1;
        this.cApp = this.desserts[this.cAppIndex];
        this.bindReson(this.cApp.status_id);
      }
    },
    appPrevious: function () {
      if (this.cAppIndex > 0) {
        this.cAppIndex = this.cAppIndex - 1;
        this.cApp = this.desserts[this.cAppIndex];
        this.bindReson(this.cApp.status_id);
      }
    },

    bindCompany: async function () {
      this.overlay = true;
      await this.$axios
        .get("Cards/GetByVenue/" + this.$store.state.eventInfo.EventId + "/media")
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
             this.Company.splice(0,0,{ card_id: "0", card_name: "All" });
            this.overlay = false;
          }
          else{
this.overlay = false;
          }
        });
    },

    bindCard: async function () {
          this.overlay=true;
      await this.$axios
        .get(
          //"Media/mediaCategory"
          "Cards/GetByVenue/" + this.$store.state.eventInfo.EventId + "/media"
        )
        .then((res) => {
          if(res.data.result.length>0){
          this.Card = res.data.result;
          this.Card.splice(0,0,{ card_id: "0", card_name: "All" });
          this.overlay=false;
          }
          
        });
    },

    // bindCartype: function (event_id) {
    //   axios
    //     .get(this.$apiPath + "Cards/GetByEventId/" + event_id, {
    //       headers: { Appkey: this.$aKey },
    //     })
    //     .then((res) => {
    //       this.CardTypeForUpdate = res.data.result;
    //     });
    // },

    // bindStatus: function () {
    //   axios
    //     .get(this.$apiPath + "Status", {
    //       headers: { Appkey: this.$aKey },
    //     })
    //     .then((res) => {
    //       this.Status = res.data.result;
    //       this.StatusForUpdate = res.data.result;
    //     });
    // },

    // bindReson: function (StatusID) {
    //   axios
    //     .get(this.$apiPath + "Reason/GetByStatusID/" + StatusID, {
    //       headers: { Appkey: this.$aKey },
    //     })
    //     .then((res) => {
    //       this.ResonForUpdate = res.data.result;
    //     });
    // },

    Reload: async function (event_id, venue_id, card_id) {
     // alert(event_id + "-" + venue_id + "-" + card_id);
      this.loadingstatus = true;
      await this.$axios
        .get(
           "Media/forBarcode/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            card_id
        )
        .then((res) => {
          this.desserts = res.data.result;
          console.log(this.desserts);
          this.loadingstatus = false;
          this.TDselected = [];
          // this.postBulkUpdate.application_id=[];
        })
        .catch()
        .finally();
    },

    close() {
      this.sheet = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.imgpreview {
  border-radius: 10px;
}
</style>
